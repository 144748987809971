.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.help-button:hover .tooltiptext {
    visibility: visible;   
}
.eventModalInput {
    border: none;
    width: 100%;
}
.eventModalInput::placeholder {
    color: blue;
}
.eventModalInput:hover{
    cursor: pointer;
    background-color: #eee;
}
.blueText {
    color: blue;
}
.cursorPointer {
    cursor: pointer;
}
.eventModalSelectListSpan {
    background-color: #2998ff;
    color: white;
    border-radius: 5px;
    margin-right: 1px;
    padding: 2px 5px;
}
.taskModalInput {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.noUnderline {
    text-decoration: none;
}
.interviewLogo {
    text-align: center;
    padding-bottom: 20px;
}
.interviewLogo img{
    width: 80px;
    padding: 10px;
    margin-right: 20px;
    border: 1px solid gray;
    border-radius: 100px;
    background: linear-gradient(to top, #3D7D7D 0%, #37A689 100%);
}
.taskLogoDiv {
    /* text-align: center; */
    padding-bottom: 20px;
    padding-top: 10px;
}
.taskLogoDiv img {
    width: 60px;
    margin-right: 20px;
}
.interviewList {
    display: block;
    position: relative;
    width: 100%;
    background: white;
    border: 1px solid lightgray;
    height: auto;
    margin:0 auto;
    overflow: auto;
}
.userDetailsInput {
    width: 70%;
    border-width: 0 0 1px;
    border-color:#d8d8d8;
    outline:0;
    color: #2998ff;
    font-weight:bold;
    margin-bottom: 5px;
}
.userDetailsInput::placeholder{
    font-weight: lighter;
}
.columnDir {
    display: flex;
    flex-direction: column;
}
.show {
    display: block;
}
.notShow {
    display: none;
}
.LinkToBtn a {
    text-decoration: none;
    height: 40px;
    font-size: 15px;
    text-align: center;
    padding: 5px 16px;
    line-height: 0;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    color: #2998ff;
    align-items: center;
}
.LinkToBtn a:hover {
    border: 1px solid #44a6ff;
    color: #2998ff;
}
.capitalize:first-letter {
    text-transform: capitalize;
}
.commentBtn {
    text-decoration: none;
    /* height: 30px; */
    font-size: 15px;
    text-align: center;
    padding: 12px;
    line-height: 0;
    background: #2998ff;
    border: 1px solid #44a6ff;
    border-radius: 20px;
    color: #fff;
    align-items: center;
}
.commentBtn:hover {
    border: 1px solid blue;
    color: #fff;
}
.droneTable tbody tr td {
    font-weight: bold;
    padding: 0 5px;
}
.userProjectDetailsInput {
    width: 100%;
    border-width: 0 0 1px;
    border-color:#d8d8d8;
    outline:0;
    color: #2998ff;
    font-weight:bold;
    margin-bottom: 5px;
}
.userProjectDetailsInput::placeholder{
    font-weight: lighter;
}
.whiteDiv {
    padding:10px; 
    background-color:white;
    border-radius:10px;
    box-shadow:0 3px 10px rgb(0 0 0 / 6%);
}
.myTextAera {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.documentFolder {
    color: white;
    padding-top: 20px;
    text-align: center;
    font-weight: bold;
    width: 150px;
    height: 105px;
    margin: 0 10px;
    margin-top: 50px;
    cursor: pointer;
    position: relative;
    background-color: #708090;
    border-radius: 0 6px 6px 6px;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59);
}

.documentFolder:before {
    content: '';
    width: 50%;
    height: 12px;
    border-radius: 0 20px 0 0;
    background-color: #708090;
    position: absolute;
    top: -12px;
    left: 0px;
}
.documentUserFoldersDiv {
    display: block;
    width: 100%;
    border: 1px solid #c3c3c3;
    display: flex;
    flex-wrap: wrap;
}
.displayNone {
    display: none;
}
.displayBlock {
    display: block;
}
.userTooltipText {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
}
.userCarrier:hover .userTooltipText {
    visibility: visible;   
}
.formeHeaderTitle {
    padding: 10px;
    font-size: 20px;
}
.formeBody {
    background-color: white;
    width: 50%;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
    border-radius: 10px;
    padding: 10px;
    margin: 2px;
}
.legacyBody {
    background-color: white;
    width: 20%;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
    border-radius: 10px;
    padding: 10px;
    margin: 2px;
}
.legacyTitleBody {
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid black;
    margin-bottom: 5px;
}
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #2998ff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.textViewBody {
    background-color: white;
    min-height: 600px;
    width: 50%;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
    border-radius: 10px;
    padding: 10px;
}

.contractorsBody {
    background-color: white;
    width: 50%;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
    border-radius: 10px;
    padding: 10px;
    margin: 2px;
}
.contractorsTitleBody {
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid black;
    margin-bottom: 5px;
}

